<template>
    <v-app class="ma-n3">
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="7" class="main-part d-none d-md-none d-lg-flex">
                    <div class="d-flex">
                        <v-img v-bind:src="this.logo"></v-img>
                    </div>
                </v-col>
                <v-col cols="12" lg="5" class="login-part d-flex align-center justify-center">
                    <v-row no-gutters class="align-start">
                        <v-col cols="12" class="login-part d-flex align-center justify-center bg-primary flex-column">
                            <div class="login-wrapper pt-md-4 pt-0">
                                <div class="d-flex d-lg-none mb-10">
                                    <v-img v-bind:src="this.logo"></v-img>
                                </div>
                                <v-container>
                                    <v-row no no-gutters class="flex-column">
                                        <v-col>
                                            <h1 class="text-h5 font-weight-bold mb-4">
                                                Your adventure starts here 🚀
                                            </h1>
                                            <p class="text-body-2 mb-8">
                                                Please enter your name and password to complete your registration.
                                            </p>
                                        </v-col>

                                        <v-form ref="form" v-model="valid" lazy-validation>
                                            <v-col v-if="errors?.email ? true : false">
                                                <v-alert dense text type="error">
                                                    {{ errors?.email?.[0] }}
                                                </v-alert>
                                            </v-col>
                                            <v-col>
                                                <v-text-field v-model="email_address" :error="errors?.email ? true : false"
                                                    :error-messages="errors?.email" label="Email Address" type="email"
                                                    :rules="emailRules" required filled dense disabled
                                                    placeholder="Enter email address..." persistent-placeholder
                                                    hide-details="auto"></v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-text-field v-model="name" :error="errors?.name ? true : false"
                                                    :error-messages="errors?.name" label="Full Name" type="text"
                                                    :rules="[rules.required]" required outlined dense
                                                    placeholder="Enter your full name..." persistent-placeholder
                                                    hide-details="auto"></v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-text-field v-model="phone" :error="errors?.phone_number ? true : false"
                                                    :error-messages="errors?.phone_number" label="Phone Number" type="number"
                                                    :rules="[rules.required]" required outlined dense
                                                    placeholder="Enter your phone number..." persistent-placeholder
                                                    hide-details="auto"></v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-text-field :error="errors?.password ? true : false"
                                                    :error-messages="errors?.password" outlined dense v-model="password"
                                                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                                                    :rules="[rules.required, rules.min]" :type="show1 ? 'text' : 'password'"
                                                    name="input-10-1" label="Enter new password"
                                                    hint="At least 6 characters" counter
                                                    @click:append="show1 = !show1"></v-text-field>
                                            </v-col>

                                            <v-col>
                                                <v-btn @click="validate" large block :loading="loading"
                                                    :disabled="!valid || loading" color="primary">
                                                    Acccept Invitation
                                                </v-btn>
                                            </v-col>

                                            <v-col>
                                                <a @click="redirectBackToLogin">Back To Login</a>
                                            </v-col>
                                        </v-form>
                                    </v-row>
                                </v-container>
                            </div>
                        </v-col>
                        <v-col cols="12" class="d-flex justify-center">
                            <v-footer>
                                <div class="primary--text text-caption">
                                    <a href="https://mediapal.net/" class="text-decoration-none">Tradedesk</a>, All rights
                                    reserved.
                                </div>
                            </v-footer>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>
  
<script>
// import ErrorMessage from "../../components/ErrorMessage.vue";

export default {
    data: () => ({
        errors: [],
        show1: false,
        email_address: "",
        password: "",
        name: "",
        token: "",
        phone: "",
        valid: true,
        loading: false,
        emailRules: [
            value => {
                if (value) return true
                return 'E-mail is requred.'
            },
            value => {
                if (/.+@.+\..+/.test(value)) return true

                return 'E-mail must be valid.'
            },
        ],
        rules: {
            required: value => !!value || 'Required.',
            min: v => v.length >= 8 || 'Min 8 characters',
        },
        credentials: {
            email_address: "",
            password: "",
        },
        params: {
            from: "",
            message: "",
        },
        error: null,
    }),
    components: {},
    computed: {
        // a computed getter
        logo() {
            if (location.hostname == "dsp.tag.co.ke") {
                return "https://live.mediapal.net/ads/logos/tradedesk/TAG.png";
            } else if (location.hostname == "desk.belvadigital.com") {
                return "https://live.mediapal.net/ads/logos/tradedesk/belva-digital.png";
            }
            return "https://live.mediapal.net/ads/logos/tradedesk/mediapal.png";
        },

        ___hostName() {
            const host = window.location.hostname;

            let url = '';

            if (host == 'https://test-tradedesk.mediapal.net' || host == 'localhost') {
                url = 'https://test-gateway.mediapal.net';
            } else if (host == 'https://tradedesk.mediapal.net') {
                url = 'https://gateway.mediapal.net';
            } else {
                url = 'https://gateway.mediapal.net';
            }

            return url;
        }
    },
    mounted() {
        this.email_address = this.$route.query.email;
        this.token = this.$route.query.token;
    },
    methods: {
        redirectBackToLogin() {
            this.$router.push({ name: "login" });
        },

        async validate() {

            this.valid = this.$refs.form.validate()

            if (this.valid) {
                this.acceptInvitation();
            }
        },

        //send password reset code
        async acceptInvitation() {
            let body = {
                email: this.$route.query.email,
                name: this.name,
                password: this.password,
                password_confirmation: this.password,
                phone_number: this.phone,
                token: this.$route.query.token,
            };

            this.loading = true;

            try {

                await this.$store.dispatch("acceptInvitation", body);

                this.$notify({
                    title: "Your account has been created successfully. You can now login.",
                    group: "success",
                });

                this.$router.push({ name: "login" });

            } catch (error) {

                let showE = error?.errors

                this.errors = showE

                this.$notify({
                    title: error.message,
                    group: "errors",
                });

            } finally {
                this.loading = false;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.container {
    padding: 0 !important;

    .logo-sm {
        height: 23%;
    }

    .main-part {
        width: 100%;
        height: 100vh;
        background-color: #f6f7ff;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .login-part {
        width: 100%;
        height: 100vh;
        background-color: #fff;
        overflow-y: auto;

        .login-wrapper {
            width: 320px;
            height: auto;
        }

        .v-footer {
            position: absolute;
            bottom: 12px;
            background-color: #fff;
        }
    }
}
</style>
  
  